import React from "react";
import Img from "gatsby-image";
import styled from "styled-components";
import SectionContainer from "../../components/SectionContainer/SectionContainer";
import SectionTitle from "../../components/SectionTitle/SectionTitle";
import mq from "../../utils/mq";

const SponsorsContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around;
  align-items: center;
  width: 100%;
  max-width: var(--max-width-desktop);
  margin: 0 auto;
`;

const ImgContainer = styled.div`
  height: auto;
  flex-basis: 50%;
  padding: 15px;

  ${mq.a768} {
    flex-basis: 20%;
  }
`;

const SponsorsSection = ({ sponsors }) => (
  <SectionContainer>
    <SectionTitle title="Sponsors" />
    <SponsorsContainer>
      {sponsors.map((s, i) => {
        const { sponsor, logo, website } = s;
        return (
          <ImgContainer index={i}>
            <a href={website} target="_blank" rel="noopener noreferrer">
              <Img
                fluid={logo.childImageSharp.fluid}
                style={{ maxWidth: "150px", margin: "0 auto" }}
                alt={`${sponsor} logo`}
              />
            </a>
          </ImgContainer>
        );
      })}
    </SponsorsContainer>
  </SectionContainer>
);

export default SponsorsSection;
